"use client"

import "react-phone-number-input/style.css"

import { GoogleAnalytics } from "nextjs-google-analytics"

import RightBanner from "@/components/ui/RightBanner"
import CallToAction from "@/components/CallToAction"
import Banner from "@/components/home/Banner"
import DesignPartners from "@/components/home/DesignPartners"
import Features from "@/components/home/Features"
import Hero from "@/components/home/Hero"
import NewArrivals from "@/components/home/NewArrivals"
import ShopByCategory from "@/components/home/ShopByCategory"
import ShopByFabric from "@/components/home/ShopByFabric"

export default function Home() {
  return (
    <>
      <main className="">
        {/* <RightBanner /> */}
        <Hero />
        <Features />
        <NewArrivals />
        <Banner />
        <ShopByCategory />
        <ShopByFabric />
        <DesignPartners />
        <CallToAction />
        <GoogleAnalytics />
      </main>
    </>
  )
}
