"use client"

import { useState } from "react"
import { useRouter } from "next/navigation"
import { PiMagnifyingGlassLight } from "react-icons/pi"

import { cn } from "@/lib/utils"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

interface Product {
  id: string
  name: string
  image: string
  price: string
  quantityAvailable: number
}

const Search = ({ className }: { className: string }) => {
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [searchResults, setSearchResults] = useState<any[] | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const router = useRouter()

  const fetchSearch = async () => {
    if (searchTerm.trim() === "") {
      setIsPopoverOpen(false)
      return
    }
    setIsPopoverOpen(true)
    setIsLoading(true)
    try {
      const response = await fetch(`api/product?search=${searchTerm}`)
      const resp = await response.json()
      if (resp.data.length > 0) {
        setSearchResults(resp.data)
      } else {
        setSearchResults([])
      }
    } catch (error) {
      console.error("Failed to fetch search results:", error)
      setIsPopoverOpen(false)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div
        className={cn(
          "relative text-gray-400 hover:text-gray-500 md:mr-4",
          className
        )}
      >
        <section className="max-w-md mx-auto">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
            <PopoverTrigger
              className="cursor-pointer w-full"
              onClick={(e) => {
                e.preventDefault()
                setSearchTerm("")
              }}
            >
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <PiMagnifyingGlassLight className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="rounded-full block w-full ps-10 text-sm text-gray-900 border border-gray-300 bg-gray-50 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                  placeholder="Search..."
                  required
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      fetchSearch()
                    }
                  }}
                />
              </div>
            </PopoverTrigger>

            <PopoverContent className="z-[1000]">
              {isLoading ? (
                <div className="p-4">Loading...</div>
              ) : searchResults && searchResults.length === 0 ? (
                <div className="p-4">No results found</div>
              ) : (
                <div className="max-h-72 overflow-y-auto">
                  <ul className="divide-y divide-gray-200">
                    {searchResults?.map((product) => (
                      <li
                        key={product.id}
                        className="p-4 cursor-pointer hover:bg-gray-100"
                        onClick={() => {
                          router.push(`/products/${product.id}`)
                          setIsPopoverOpen(false) // close popover when clicked
                        }}
                      >
                        <div className="flex items-center">
                          <img
                            src={
                              product.productImages[0] ||
                              "/assets/images/apparel_1.jpeg"
                            }
                            alt={product.name}
                            className="w-16 h-16 object-cover rounded-md"
                          />
                          <div className="ml-4">
                            <h3 className="text-sm font-medium text-gray-900">
                              {product.name}
                            </h3>
                            <p className="mt-1 text-sm text-gray-500">
                              ${product.price}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </PopoverContent>
          </Popover>
        </section>
      </div>
    </>
  )
}

export default Search
