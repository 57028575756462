import { useState } from "react"
import Link from "next/link"
import { AnimatePresence, motion } from "framer-motion"

import { cn } from "@/lib/utils"

import ProductCard from "../product/ProductCard"

export const HoverEffect = ({
  items,
  className,
}: {
  items: {
    id: number
    name: string
    price: number
    category: { name: string }
  }[]
  className?: string
}) => {
  let [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

  return (
    <div className={cn("grid grid-cols-2 lg:grid-cols-3", className)}>
      {items.map((item, idx) => (
        <Link
          href={`/products/${item.id}`}
          key={item.id}
          className="relative group block p-2 h-full w-full"
          onMouseEnter={() => setHoveredIndex(idx)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <AnimatePresence>
            {hoveredIndex === idx && (
              <motion.span
                className="absolute inset-0 h-full w-auto max-w-[20rem] bg-green-200 dark:bg-green-800/[0.8] block rounded-3xl"
                layoutId="hoverBackground"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.15 },
                }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.15, delay: 0.2 },
                }}
              />
            )}
          </AnimatePresence>
          <ProductCard
            product={item}
            className="rounded-2xl h-full overflow-hidden border border-transparent dark:border-white/[0.2] group-hover:border-slate-700 relative z-20"
          />
        </Link>
      ))}
    </div>
  )
}
