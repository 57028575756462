import { Skeleton } from "@/components/ui/skeleton"

const ProductSkeleton = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
      {[...Array(6)].map((_, idx) => (
        <div
          className="flex flex-col space-y-3 rounded-xl border border-transparent dark:border-white/[0.2] overflow-hidden"
          key={idx}
        >
          <Skeleton className="h-56 w-64 rounded-xl" />
          <div className="space-y-2">
            <Skeleton className="h-4 w-[250px]" />
            <Skeleton className="h-4 w-[200px]" />
          </div>
        </div>
      ))}
    </div>
  )
}

export default ProductSkeleton
