"use client"

import { useEffect, useState } from "react"
import Link from "next/link"
import axios from "axios"
import { event } from "nextjs-google-analytics"

import ProductSkeleton from "../ProductSkeleton"
import { Button } from "../ui/button"
import { HoverEffect } from "../ui/card-hover-effect"
import { ScrollArea, ScrollBar } from "../ui/scroll-area"

type TabType = {
  Name: string
  Value: string
  Filter: string
}

const Tabs = [
  {
    Name: "All",
    Value: "all",
    Filter: "",
  },
  {
    Name: "Men",
    Value: "MEN",
    Filter: "fit=MEN",
  },
  {
    Name: "Women",
    Value: "WOMEN",
    Filter: "fit=WOMEN",
  },
  {
    Name: "Limited Edition",
    Value: "LIMITEDEDITION",
    Filter: "isLimitedEdition=true",
  },
]

const NewArrivals = () => {
  const [selectedTab, setSelectedTab] = useState<TabType>(Tabs[0])
  const [loading, setLoading] = useState<boolean>(true)
  const pageSize = 0
  const [products, setProducts] = useState<any[]>([])

  const handleTabSelection = (tabValue: string) => {
    const tabDetails: TabType | undefined = Tabs.find(
      (t) => t.Value === tabValue
    )

    if (tabDetails) {
      setSelectedTab(tabDetails)
      fetchData(tabDetails?.Filter)
    }
  }

  // Fetch products based on selected categories
  const fetchData = async (filter?: string) => {
    setLoading(true)
    let url = `/api/product?cursor=${pageSize}&take=6`
    if (filter) {
      url += `&${filter}`
    }

    const response = await axios.get(url)
    if (!response) {
      console.error(response)
      return
    }
    setProducts(response?.data?.data)
    setLoading(false)
  }

  const getQueryParam = () =>
    selectedTab.Filter ? `?${selectedTab.Filter}` : selectedTab.Filter

  useEffect(() => {
    fetchData()
  }, [])

  const onClickViewMore = () => {
    event("button_click", {
      category: "navigation_home_screen_new_arrivals",
      label: "View More",
    })
  }

  return (
    <section className="px-2 md:container">
      <div className="flex mb-12 flex-col items-center justify-center">
        <h2 className="font-semibold text-[20px] md:text-[40px] mb-5 text-[#444]">
          New Arrivals
        </h2>
        <p className="text-[#a5a5a5] text-center">
          Beyond all the trends, sustainable style empowers you and cares for
          the Earth. Explore our Ethical and Sustainable collection at Alien
          Wardrobe.
        </p>
      </div>
      <ScrollArea className="mb-12 w-full whitespace-nowrap py-4">
        <div className="md:flex md:px-[12rem] justify-center items-center">
          {Tabs.map((tab, index) => (
            <Button
              key={index}
              type="button"
              variant={`${selectedTab.Value === tab.Value ? "default" : "ghost"}`}
              className={`${selectedTab.Value === tab.Value ? "mx-2 rounded-full bg-gradient-to-br from-green-800 to-green-500 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" : "rounded-full mx-2 px-6 bg-gray-100"}`}
              onClick={() => handleTabSelection(tab.Value)}
            >
              <div className="relative overflow-hidden group">
                <span className="invisible">{tab.Name}</span>
                <span className="absolute top-0 left-0 group-hover:-translate-y-full transition-transform ease-in-out duration-500 hover:duration-300">
                  {tab.Name}
                </span>
                <span className="absolute top-0 left-0 translate-y-full group-hover:translate-y-0 transition-transform ease-in-out duration-500 hover:duration-300">
                  {tab.Name}
                </span>
              </div>
            </Button>
          ))}
          <ScrollBar orientation="horizontal" className="mt-2" />
        </div>
      </ScrollArea>

      <div className="max-w-6xl mx-auto md:px-8 text-white">
        {loading ? (
          <ProductSkeleton />
        ) : products.length > 0 ? (
          <HoverEffect items={products} />
        ) : (
          <p className="text-center text-gray-500">No products found</p>
        )}
      </div>

      <div className="flex mt-10 justify-center items-center">
        <Link
          href={`/products${getQueryParam()}`}
          className="rounded-full bg-gradient-to-br from-green-800 to-green-500 px-6 py-2.5 text-xs font-semibold text-white shadow-lg hover:bg-green-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          View All
        </Link>
      </div>
    </section>
  )
}

export default NewArrivals
